/* global gapi */
import React, { Component } from "react";
import appRequest from "./utilities/appRequest";
import PhoneInput from "react-phone-input-auto-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faSpinner } from "@fortawesome/free-solid-svg-icons";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company: "",
      comment: "",
      response: null,
      loading: false,
    };

    this.form = React.createRef();
    this.validate = this.validate.bind(this);
  }
  validate() {
    return this.form.current.reportValidity();
  }
  handleChange(e, element) {
    console.log(element);
    this.setState((prevState) => ({ [element]: e.target.value }));
  }
  handleSubmit(e, data) {
    const valid = this.validate();

    e.preventDefault();
    if(valid){
    this.setState({ loading: true });
    const mailgun = {
      email: data.email,
      subject: "contact request from website",
      message: `<div>
          <p>${data.first_name} has sent an inquire from the contact from on jrtransinc.com.  The details are below.</p>
          <ul>
            <li>Name: ${data.first_name} ${data.last_name}</li>
            <li>Email: ${data.email}</li>
            <li>Phone: ${data.phone}</li>
            <li>Company: ${data.company}</li>
            <li>Comment: ${data.comment}</li>
          <ul>
          </div>`,
    };
    appRequest
      .sendEmail(mailgun)
      .then((resp) => {
        this.setState({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          company: "",
          comment: "",
          response: "Thanks for contacting us. We'll get back to you shortly.",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          response: "We had trouble sending the email. Please try again or email dispatch@jrtransinc.com",
          loading: false,
        });
      });
    }
  }
  render() {
    const { loading } = this.state;
    return (
      <div className="box">
        <form ref={this.form}>
          <div className="inputBox">
            <input
              type="text"
              name="first_name"
              onChange={(e) => {
                this.handleChange(e, "first_name");
              }}
              required
              value={this.state.first_name}
            />
            <label>First Name</label>
          </div>
          <div className="inputBox">
            <input
              type="text"
              name="last_name"
              required
              value={this.state.last_name}
              onChange={(e) => {
                this.handleChange(e, "last_name");
              }}
            />
            <label>Last Name</label>
          </div>
          <div className="inputBox">
            <input
              type="email"
              name="email"
              required
              value={this.state.email}
              onChange={(e) => {
                this.handleChange(e, "email");
              }}
            />
            <label>Email</label>
          </div>
          <div className="inputBox">
            <input
              type="text"
              name="phone"
              required
              value={this.state.phone}
              onChange={(e) => {
                this.handleChange(e, "phone");
              }}
            />
            <label>Phone</label>
          </div>
          <div className="inputBox">
            <input
              type="text"
              name="company"
              required
              
              value={this.state.company}
              onChange={(e) => {
                this.handleChange(e, "company");
              }}
            />
            <label>Company</label>
          </div>
          <div className="inputBox">
            <textarea
              rows="4"
              cols="30"
              name="comment"
              required
              value={this.state.comment}
              onChange={(e) => {
                this.handleChange(e, "comment");
              }}
            />
            <label>Comment</label>
          </div>
          <label className="ohnohoney" htmlFor="name"></label>
          <input
            className="ohnohoney"
            autoComplete="off"
            type="text"
            id="name"
            name="name"
            placeholder="Your name here"
          />
          <label className="ohnohoney" htmlFor="email"></label>
          <input
            className="ohnohoney"
            autoComplete="off"
            type="email"
            id="email"
            name="email"
            placeholder="Your e-mail here"
          ></input>
          <button
            name="contact_send"
            value="send"
            disabled={loading}
            onClick={(e) => {
              this.handleSubmit(e, this.state);
            }}
          >
            {loading && <span className="spinner"><FontAwesomeIcon icon={faSpinner} spin /> </span>}
            {loading && <span>Sending Email</span>}
            {!loading && <span>Send Email</span>}
          </button>
        </form>
        <div>{this.state.response}</div>
      </div>
    );
  }
}
export default App;
