import axios from "axios";

// import Promise from "Promise";
const appRequest = {
  sendEmail: (data) => {
    let url = `https://us-central1-feisty-return-123914.cloudfunctions.net/mailgun-integration/api/contact`;
    return axios({
      url: url,
      method: "POST",
      data
    })
      .then((resp) => {
        console.log(resp);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        console.log("error: ", err);
        return Promise.reject(err);
      });
  },
};
export default appRequest;

